.lpcasino__reviews{
    padding: 100px 0 0;
}

.lpcasino__review_single_box{
    text-align: center;
    margin: 40px 0;

    img{
        width: 100px;
    }

    p{
        font-size: 16px;
        padding: 20px 40px 0;

        @media screen and (max-width: 800px){
            font-size: 12px;
            padding: 20px 10px 0;
            font-weight: 500;
        }
    }

    h5{
        padding: 10px 0 20px;
        font-size: 15px;
        font-weight: 500;
    }
}

.lpcasino__review_single_img_top,.lpcasino__review_single_img_bottom{
    width: 100px;
    margin: 0 auto;
}

.lpcasino__review_single_item{
    background-color: rgb(254, 254, 254);
    margin: 60px 10px;
    border-radius: 10px;

    box-shadow: 0 0 10px rgb(175, 175, 175);
}

.lpcasino__review_button_box{
    background-color: #5361F5;
    padding: 190px 0 110px;
    margin-top: -200px;
    display: flex;
    justify-content: center;

    button{
        font-size: 15px;
        font-family: "Mulish", sans-serif;
        padding: 10px 15px;
        border: none;
        color: #fff;

        a{
            color: #fff;
        }

        @media screen and (max-width: 800px){
            display: flex;
            width: 100%;
            text-align: center;
            justify-content: center;
            margin: 10px 0;
            padding: 17px 17px;
            font-size: 18px;
        }

        &:nth-child(1){
            background-color: #5BCBA5;
            text-align: center;
        }

        &:nth-child(2){
            background-color: #E95A8B;
            margin-left: 20px;

            @media screen and (max-width: 800px){
                margin-left: 0;
            }
        }
    }
}

#review_single_logo{
    margin-top: -50px;
    width: 100px!important;
}

#review_single_star{
    width: 100px!important;
    margin: 0 auto;
}

