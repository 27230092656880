.privacy-policy{
    font-family: "Roboto", sans-serif;
    &__box{
        h4{
            font-size: 23px;
            font-weight: 500;
            color: #4152FD;
        }
        p{
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
        }

        ul{
            padding-left: 30px;
        }

        ol{
            list-style-position: inside;
        }
    }
}