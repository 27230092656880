$white: #ffffff;
$btnGreen: #5BCBA5;
$btnBlue: #8490E5;
$btnPink: #E95A8B;

.lpcasino__hero_section{
    background-color: #5361F5;
    padding: 30px 0 0;
    overflow: hidden;
}

.lpcasino__container{
    display: grid;
    grid-template-columns: 43% 50%;
    justify-content: space-between;
    position: relative;

    @media screen and (max-width: 800px){
        grid-template-columns: 100%;
    }
}

.lpcasino__left_section{
    padding: 100px 0;

    @media screen and (max-width: 800px){
        position: relative;
        z-index: 10;
    }
 
}

.lpcasino__hero_title{
    font-weight: 800;
    font-size: 45px;
    color: $white;
    line-height: 45px;
    padding-bottom: 25px;
}

.lpcasino__hero_paragraph{
    font-weight: 400;
    font-size: 20px;
    color: $white;
    line-height: 35px;
    padding-bottom: 20px;

    @media screen and (max-width:800px){
        text-shadow: 1px 1px 11px rgb(8, 8, 8);
    }
}

.lpcasino__hero_btn_section{
    button{
        font-size: 15px;
        font-family: "Mulish", sans-serif;
        padding: 10px 15px;
        border: none;
        color: $white;

        a{
            color: #fff;
        }

        @media screen and (max-width: 800px){
            display: flex;
            width: 100%;
            text-align: center;
            justify-content: center;
            margin: 10px 0;
            padding: 17px 17px;
            font-size: 18px;
        }

        &:nth-child(1){
            background-color: $btnGreen;
            text-align: center;
        }

        &:nth-child(2){
            background-color: $btnPink;
            margin-left: 20px;

            @media screen and (max-width: 800px){
                margin-left: 0;
            }
        }
    }
}

.lpcasino__right_section{
    overflow: hidden;
    @media screen and (max-width: 800px){
        z-index: 9;
        opacity: 0.5;
        position: absolute;
        width: 250%;
        margin-left: -40px;
    }
    img{
        max-width: 60%;
        overflow: hidden;
        position: absolute;

        @media screen and (max-width:800px){
            top: 0;
            position: relative;
        }
    }
}