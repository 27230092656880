.terms_section{
    font-family: "Roboto", sans-serif;
    &__box{

        p{
            color: #555;
            font-weight: 400;
            line-height: 30px;
        }

        h6{
            color: #4152FD;
            padding-top: 10px;
            font-size: 23px;
        }

        ul{
            padding-left: 35px;

            li{
                font-size: 16px;
                font-weight: 400;
                color: #888;
            }
        }
    }
}