.pdf{
    background-color: beige;
    display: inline-block;
    border-radius: 10px;
    font-family: "Roboto", sans-serif;
    background-color: #4152FD;
    padding: 10px;
    margin-top: 5px;
    transition: all ease 0.5s;

    &:hover{
        filter: saturate(2);
        cursor: pointer;
        transition: all ease 0.5s;
    }

    &:hover .pdf__link span:nth-child(2){
        padding-left: 10px;
        transition: all ease 0.5s;
    }
    &__link{
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        display: flex;
        align-items: center;
        align-content: center;
        transition: all ease 0.5s;

        &:hover{
            color: #f1f1f1;
            text-decoration: none;
        }

        span{
            display: flex;
            align-items: center;
            align-content: center;
            justify-content: center;
            transition: all ease 0.5s;
        }

        span:nth-child(2){
            padding-left: 2px;
            margin-top: 2px;
        }
    }
}