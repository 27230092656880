.lpcasino__brands_provider{
    padding: 50px 0 100px;
}

.lpcasino__brands_provider_paragraph{
    font-size: 18px;
    text-align: center;
    padding: 10px 0;
    max-width: 900px;
    margin: 0 auto;
}

.lpcasino__brands_provider_img_box{
    display: grid;
    grid-template-columns: repeat(5, 20%);
    justify-content: center;
    margin: 0 auto;
    
    @media screen and (max-width: 800px){
        grid-template-columns: repeat(2, 50%);
    }

    img{
        width: 80%;
        margin: 0 auto;
    }
}