.custom-link {
  color: #333;
  text-align: center;
  text-decoration: none;
  display: block;
  border: 0;
  cursor: pointer;
  margin: 0;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  width: 100%;
  font-size: 16px;
  &:hover {
    color: #18bcf2;
    text-decoration: none;
  }
}

.green-link {
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background-color: #5bcba5;
  text-decoration: none;
  color: #ffffff;
  margin-left: 10px;
}

.green-link:hover {
  background-color: darken(#5bcba5, 10%);
  text-decoration: none;
  color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.blue-link {
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background-color: #303f9f;
  text-decoration: none;
  color: #ffffff;
  margin-left: 10px;
}

.blue-link:hover {
  background-color: darken(#303f9f, 10%);
  text-decoration: none;
  color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.profile__thumb {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border: 0;
  outline: none;
  &:focus {
    outline: none;
  }
  cursor: pointer;
  .profile__name {
    margin: 0 10px;
    color: #ffffff;
    font-weight: 600;
  }
}

.profile__dropdown {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
}

.MuiPopover-paper {
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.2);
}

.top-menu-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 200px;

  @media screen and (max-width: 1360px){
    padding: 0 20px;
  }
}

.nav__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 230px;
  margin-left: 20px;
}

.mobile-toggle-btn {
  display: none;
}

@media (max-width: 950px) {
  .top-main-menu {
    flex-direction: column;
    align-items: flex-end;
  }
  .top-toggle-menu {
    margin-bottom: 5px;
  }
}

@media (max-width: 700px) {
  .top-main-menu {
    position: fixed;
    width: 275px;
    height: 100vh;
    top: 0;
    right: 0;
    justify-content: flex-start;
    background-color: #f5f6ff;
    padding: 0 25px;
    padding-top: 85px;
    transition: all 0.3s ease;
    transform: translateX(101%);
  }
  .top-main-menu.active {
    transform: translateX(0);
  }
  .mobile-toggle-btn {
    display: block;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border: 0;
    padding: 0;
    outline: none;
  }
  .mobile-toggle-btn {
    svg {
      transition: all 0.3s ease;
    }
  }
  .mobile-toggle-btn.active {
    svg {
      fill: #5361f5;
    }
  }
  .top-main-menu .top-toggle-menu {
    flex-direction: column;
    align-items: flex-end;
    display: flex;
    a {
      color: #5361f5;
      background-color: transparent;
      border-color: 0;
      font-weight: 600;
      font-size: 21px;
    }
  }
}
