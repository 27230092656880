.lpcasino__payment{
    padding: 90px 0;
}

.lpcasino__payment_box{
    display: grid;
    grid-template-columns: 40% 50%;
    justify-content: space-between;

    @media screen and (max-width:800px){
        grid-template-columns: 100%;
    }
}

.lpcasino__payment_left{
    p{
        padding-top: 30px;
        font-size: 18px;
    }
}

.lpcasino__payment_right{
    display: grid;
    grid-template-columns: repeat(3, 33%);

    @media screen and (max-width: 800px){
        grid-template-columns: repeat(2, 50%);
        gap: 20px;
    }
    img{
        width: 80%;
    }
}

.section-heading-align-left{
    justify-content: flex-start;
}