@font-face {
  src: url(/src/assets/fonts/Montserrat-Bold.ttf);
  font-family: sans-serif;
}

* {
  outline: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html, body{
  scroll-behavior: smooth;
}

body{
  font-family: "Montserrat", sans-serif;
}

.App {
  text-align: center;
}

button,
button:focus {
  outline: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.center-block {
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.page__title {
  padding: 30px 0;
  width: 100%;
}

.no-result {
  text-align: center;
  width: 100%;
}

.wrapper {
  display: flex;
  align-items: stretch;
  height: 100vh;
  width: 100%;
  background-color: #f5f6f7;
  padding-top: 80px;
}

.row {
  height: 100%;
}

.main-content {
  padding: 0 15px;
}

.menu-container {
  height: 100%;
  margin: 0 -15px;
  background: #fff;
  height: 100%;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.05);
}

.menu-logo {
  margin-bottom: 40px;
  background: #5361f5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.menu-logo svg {
  width: 185px;
  height: 76px;
}

.main-menu {
  padding: 0;
  margin: 0;
  padding-top: 10px;
}

.main-menu li {
  list-style-type: none;
}

.nav-link {
  color: #333;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  padding: 8px 20px;
  display: flex;
  font-size: 15px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.nav-link svg {
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
}

.nav-link:hover {
  background: #18bcf2;
  color: #ffffff;
}

.nav-link:hover svg {
  fill: #ffffff;
}

.nav-link.is-active {
  background: #18bcf2;
  color: #ffffff;
}

.nav-link.is-active svg {
  fill: #ffffff;
}

.main-content .table.report-table {
  min-height: auto;
}

.table thead th {
  text-transform: uppercase;
  font-weight: 600;
}

.menu-tab__title {
  display: block;
  padding: 8px 16px;
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 20px;
}

.menu-item__title {
  position: relative;
  display: block;
  cursor: pointer;
  color: #333;
  padding: 5px 16px;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.menu {
  padding: 0;
  background-color: #384c61;
  transition: all 0.3s ease-in-out;
}

.toggle-menu.active-toggle .menu {
  height: auto;
  opacity: 1;
  visibility: visible;
}

.loader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.brandsSelect {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 0;
}

.brandsContainer {
  position: relative;
}

.brands {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: absolute;
  padding: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  z-index: -100;
  top: 450%;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  width: 200px;
}

.brands.is-active {
  opacity: 1;
  visibility: visible;
  z-index: 10;
  top: 150%;
}

.brands input {
  margin-right: 5px;
}

.brands:after {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
  position: absolute;
  content: "";
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.brands label {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.toggleBrandSelect {
  background-color: #42d0ff;
  width: 200px;
  height: 35px;
  border: 0;
  border-radius: 4px;
  color: #ffffff;
  outline: none;
}

.resetBrandList {
  margin-left: 25px;
  background-color: #4d6680;
  width: 150px;
  height: 35px;
  border: 0;
  border-radius: 4px;
  color: #ffffff;
  outline: none;
}

.dashboard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 40px;
}

.card {
  width: 16%;
  padding: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.1);
  margin:10px;
}

.card .content {
  padding: 0;
  background: #fff;
  text-align: center;
}

.title {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}
.symbol {
  color: #828e99;
  font-size: 20px;
}

.cardStat {
  color: #4383cc;
  font-size: 28px;
}

.table-margin {
  margin-bottom: 40px;
}

.dataTables_wrapper {
  width: 100%;
}

.dataTables_wrapper .row {
  height: auto;
}

.dataTables_wrapper .pagination {
  justify-content: flex-end;
}

.page-link {
  cursor: pointer;
}

.sorting,
.sorting_asc,
.sorting_desc {
  cursor: pointer;
}

.top-line {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.datepicker__col {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 0;
}
.brandpicker__col {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.top_menu {
  background-color: #5361f5;
  height: 80px;
  box-shadow: 0 5px 9px 0 rgba(0, 0, 0, 0.05);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.top-menu-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.top-main-menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding-left: 50px;
}

.top-main-menu li {
  list-style-type: none;
}

.top-nav-link {
  color: #9fa7ff;
  text-decoration: none;
  margin: 0 10px;
  transition: all 0.3s ease-in-out;
  padding-bottom: 5px;
}

.top-nav-link:hover {
  color: #ffffff;
  text-decoration: none;
}

.top-nav-link.is-nav-active {
  color: #ffffff;
  border-bottom: 1px solid #fff;
}

.top-menu-container .menu-logo {
  margin-bottom: 0;
  max-width: 100%;
  height: 100%;
}

.top-main-navbar {
  width: 100%;
}

.top-menu-container .menu-logo svg {
  width: 150px;
  height: 60px;
}

.container {
  position: relative;
}

.data__wrapper {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
  margin-bottom: 30px;
  padding: 15px;
  background-color: #ffffff;
}

.fhzgrH div:first-child {
  overflow: visible !important;
  white-space: normal !important;
}

.section-inner{
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

.section-heading{
  display: flex;
  text-align: center;
  justify-content: center;
}

.section-heading h3{
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 30px;
}

.section-heading h3:nth-child(2){
  padding-left: 15px;
  color: #4A5CD8;
}