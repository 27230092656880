.profile__form{
    padding-top: 20px;
    width: 100%;
    label{
        width: 150px;
        cursor: pointer;
    }
    input{
        width: 70%;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 5px;
        padding: 10px;
        transition: all .3s ease-in-out;
        &:hover{
            border-color: #000;
        }
        &:focus{
            border-color: #5361f5;
        }
    }
}