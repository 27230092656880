.assets-list {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.asset {
  width: 49%;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.asset-thumb {
  width: 236px;
  height: 236px;
  background-color: #ebeced;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }
}

.casino-title {
  font-size: 24px;
  font-weight: 600;
  display: block;
  margin-bottom: 20px;
}

.asset-description {
  width: 67%;
  padding: 20px;
}

.asset-link {
  width: 100%;
  overflow-x: scroll;
  border: 1px solid #cacaca;
  border-radius: 4px;
  padding: 10px;
}

.asset-link .tag {
  white-space: nowrap;
}

.asset-image{
  height:80%;
  width:80%;
  margin:10px;
}
