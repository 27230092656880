.lpcasino__brand_upper{
    background-color: #4A5CD8;
    padding: 60px 0 180px;
    text-align: center;

    h3{
        font-size: 30px;
        font-weight: 800;
        color: #fff;
    }

    p{
        color: #fff;
        font-size: 18px;
        padding: 10px 0;
    }
}

.lpcasino__brand_item_container{
    display: grid;
    grid-template-columns: repeat(3, 33%);
    margin: -180px auto 0;
    justify-content: space-between;

    @media screen and (max-width: 800px){
        grid-template-columns: 100%;
        // margin-top: 180px;
    }
}

.lpcasino__brand_item_container_one{
    display: grid;
    margin: -180px auto 0;
    justify-content: space-between;

    @media screen and (max-width: 800px){
        grid-template-columns: 100%;
    }
}

.lpcasino__brand_standard_single{
    text-align: center;
    display: flex;
    justify-content: center;
    position: relative;
    
    h4{
        position: absolute;
        top: 70px;
        font-weight: 700;
        font-size: 22px;

        @media screen and (max-width: 700px){
            font-size: 15px;
        }
    }

    p{
        @media screen and (max-width: 800px){
            font-size: 13px;
            max-width: 150px;
        }
        
        @media screen and (max-width: 600px){
            font-size: 13px;
            max-width: 120px;
        }

        position: absolute;
        max-width: 250px;
        top: 100px;
        font-size: 17px;
    }

    img{
        position: relative;
        width: 100%;
    }
}

.lpcasino__brand_item_container_new{
    margin: -180px auto 0;
}