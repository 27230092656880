.lpcasino__brand_blocks{
    display: grid;
    grid-template-columns: repeat(2,50%);
    position: relative;
}

.lpcasino__brand_blocks_single{
    position: relative;

    &:first-child{
        h3{
            color: #4A5CD8;
        }
    }

    &:nth-child(2){
        h4{
            color: #4A5CD8;
        }

        h3{
            color: #fff;
        }
    }

    &:nth-child(3){
        h4{
            color: #5BCBA5;
        }
        h3{
            color: #fff;
        }
    }

    &:nth-child(4){
        h4{
            color: #5BCBA5
        }

        h3{
            color: #4A5CD8;
        }
    }

    h4{
        position: absolute;
        font-size: 25px;
        font-weight: 600;
        top: 90px;
        left: 50px;

        @media screen and (max-width: 800px){
            font-size: 15px;
            top: 10px;
            left: 10px;
        }
    }

    h3{
        position: absolute;
        font-size: 45px;
        font-weight: 700;
        top: 140px;
        left: 50px;
        max-width: 300px;

        @media screen and (max-width: 800px){
            font-size: 18px;
            top: 44px;
            left: 10px;
        }
    }

    img{
        width: 100%;
    }
}