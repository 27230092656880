.lpcasino__brandcommission{
    padding: 40px 0;
    font-family: 'Montserrat', sans-serif;
}

.lpcasino__brand_commission_single_container{
    display: flex;
    justify-content: center;
    grid-gap: 20px;
    padding: 40px 0;

    @media screen and (max-width:800px){
        display: grid;
    }
}

.lpcasino__brand_commission_single{
    background-color: #fff;
    box-shadow: 0 0 10px rgba(109, 109, 109, 0.259);
    border-radius: 10px;
    padding: 30px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;

    span{
        padding: 10px 16px;
        background-color: #4A5CD8;
        color: #fff;
        font-size: 18px;
        font-weight: 800;
        border-radius: 50%;
        margin-right: 20px;
    }

    p{
        max-width: 250px;
        font-size: 19px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 0;
    }
}