.enter {
  opacity: 0;
  transform: scale(0);
}

.enterActive {
  opacity: 1;
  transform: scale(1);
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}

.exit {
  opacity: 1;
  transform: scale(1);
}

.exitActive {
  opacity: 0;
  transform: scale(0);
  transition: transform 250ms ease-in-out, opacity 250ms ease-in-out;
}

.toggleSettings {
  display: block;
  width: 100%;
  padding: 10px;
  color: #333333;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  margin-bottom: 15px;
}

.toggleSettings:last-child {
  margin-bottom: 0;
}

.toggleSettings:hover {
  color: #333333;
  text-decoration: none;
  border-color: #000;
}

a.active {
  border-color: #5361f5;
  color: #ffffff;
  background-color: #5361f5;
}

a.active:hover {
  color: #ffffff;
  border-color: #5361f5;
}

.settings__title {
  text-align: center;
}

.profile img {
  border-radius: 100px;
  text-align: center;
  height: 50%;
}

.profile__descr {
  text-align: center;
}
