.docs {
  padding-bottom: 15px;
  width: 100%;
}

.doc__title {
  font-size: 28px;
  margin-top: 15px;
  display: block;
  margin-bottom: 15px;
}

.doc__text {
  display: block;
  font-size: 16px;
  font-weight: 400;
}

.doc__code {
  margin: 0;
  padding: 0;
  li {
    list-style-type: none;
  }
}

.postman {
  width: 100%;
  border-radius: 20px;
}

.code {
  display: block;
  padding: 15px;
  border-radius: 5px;
  background-color: #2b2b2b;
  border: 1px solid #c1c1c1;
  margin-bottom: 10px;
  width: 100%;
  color: #9ca9b8;
  .func {
    color: #c37437;
  }
  .string {
    color: #637f52;
  }
  .const {
    color: #916FA2
  }
  .brown {
    color: #b09e77;
  }
  .main {
    color: #e7b25c;
  }
  .normal {
    color: #9ca9b8;
  }
  .client {
    color: #639DA8;
  }
  .package {
    color: #9BA96D;
  }
}

.code__title {
  font-size: 18px;
  text-align: center;
  margin-bottom: 10px;
  font-weight: 600;
}
