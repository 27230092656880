.faq-title {
  font-size: 30px;
  font-weight: bold;
}
.card-faq {
  font-size: 24px;
  border-radius: 20px;
  height: auto;
  width: auto;
  padding: 20px;
  list-style: none;
}

.faq{
  font-family: "Roboto", sans-serif;
  &__title{
    color: #4152FD;
    text-decoration: none;
    font-size: 23px;
    font-weight: 500;
  }

  &__card{
    padding: 0;
    font-size: 18px;

    li{
      font-weight: 400;
      font-size: 16px;
    }
  }
}
