.MuiAlert-root {
  margin-bottom: 20px;
}

form {
  width: 100%;
}

.alert--error {
  display: block;
}

.form-group {
  position: relative;
  input {
    padding: 14px;
    height: auto;
  }
  &:focus {
    label {
      top: 0;
    }
  }
}

.form__row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.signup_title{
  display: block;
  width: 100%;
}

.form__field{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 20px;
  input{
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 3px;
  }
  label{
    margin-bottom: 0;
  }
}

.required-field {
  color: darkred;
  font-weight: bold;
}
