.date-picker-container {
  position: relative;
}

.toggleDatePicker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #4d6680;
  border: 0;
  border-radius: 7px;
  padding: 5px 10px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.toggleDatePicker:hover {
  border: 0;
  outline: none;
  background-color: #7188a0;
}

.toggleDatePicker:focus {
  border: 0;
  outline: none;
}

.toggleDatePicker img {
  width: 35px;
  height: 35px;
  margin-right: 5px;
}

.startDate {
  margin-right: 5px;
}

.endDate {
  margin-left: 5px;
}

.date-picker {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  top: 55px;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.date-picker.is-show {
  opacity: 1;
  visibility: visible;
  z-index: 100;
}
