.success__message {
  width: 100%;
  padding: 15px;
  background-color: #dedede;
  text-align: center;
  p {
    margin-bottom: 0;
    text-align: center;
    a {
      display: inline-block;
    }
  }
}
