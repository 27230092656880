.lpcasino__service_box{
    display: grid;
    grid-template-columns: repeat(3, 30%);
    gap: 20px;
    justify-content: center;
    margin: -60px auto 40px;
    font-family: "Mulish", sans-serif;
    z-index: 20;
    position: relative;

    @media screen and (max-width: 800px){
        grid-template-columns: 100%;
    }
}

.lpcasino__service_single{
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 30px 10px;
    box-shadow: 0 0 10px rgba(109, 109, 109, 0.259);

    img{
        padding: 20px 0 10px;
    }

    h3{
        font-size: 20px;
        font-weight: 800;
        padding-bottom: 10px;
    }

    p{
        font-size: 16px;
        font-weight: 600;
    }
}