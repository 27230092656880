$primaryBgColor: #4152fd;
$secondaryFont: 'Montserrat', sans-serif;

#banner.banner-container {
  width: 100%;
  padding-bottom: 62.52px;
  padding-top: 76px;
  background-color: $primaryBgColor;
  position: relative;

  h1 {
    font-size: 60px;
    line-height: 1.5;
    font-weight: 900;
    text-transform: uppercase;
    color: #ffffff;
  }

  h1.outline {
    -webkit-text-stroke: 3px #ffffff;
    -webkit-text-fill-color: transparent;
  }

  h2 {
    font-size: 27px;
    line-height: 1.5;
    font-weight: 900;
    text-transform: uppercase;
    color: #ffffff;
  }

  img {
    position: absolute;
    &#first {
      top: 0;
      right: 100px;
    }
    &#second {
      top: 0;
      right: 0;
    }
    &#third {
      top: 297px;
      right: 248px;
    }
    &#fourth {
      top: 284px;
      right: 0;
    }
    &#fifth {
      top: 241px;
      right: 6.67px;
    }
  }

  button.green-button {
    margin-top: 27.52px;
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 36px;
    }

    h1.outline {
      -webkit-text-stroke: 2px #ffffff;
    }

    h2 {
      font-size: 16px;
    }

    img {
      display: none;
    }

    button.green-button {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

#services.service-container {
  width: 100%;
  background-color: #fff;

  .service__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0 54px 0;
  }

  .service__item {
    width: 30%;
    text-align: center;
    // transition: all 0.3s ease-in-out;

    p {
      font-family: 'Mulish';
      font-style: normal;
      font-weight: 800;
      font-size: 20px;
      line-height: 1.5;
      color: #373f41;
      margin: 0;
    }
  }

  @media (max-width: 800px) {
    .service__list {
      flex-direction: column;
      align-items: center;
      gap: 32px;
    }
    .service__item {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 24px;
      text-align: left;

      p {
        font-size: 16px;
      }

      img {
        zoom: 0.7;
      }
    }
  }
}

#target-markets.target-markets-container {
  width: 100%;
  background: #5361f5;
  padding-top: 43px;
  padding-bottom: 42px;
  position: relative;

  .section-inner {
    position: relative;
    z-index: 2;
  }

  h1 {
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 53px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }

  .target-markets_row {
    display: flex;
    flex-direction: row;

    .target-markets__left {
      width: 56%;
      margin-right: 64px;

      .target-markets__flag {
        display: flex;
        margin-top: 25px;

        .flag_wrap {
          text-align: center;
          margin-right: 50px;

          &:last-child {
            margin-right: 0;
          }

          img {
            width: 50px;
            height: 50px;
          }

          p {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 36px;
            text-align: center;
            font-feature-settings: 'salt' on, 'liga' off;
            color: #ffffff;
            margin-top: 5px;
          }

          @media (max-width: 800px) {
            margin-right: 40px;

            img {
              width: 40px;
              height: 40px;
            }

            p {
              font-size: 16px;
              line-height: 29px;
            }
          }
        }
      }

      ul {
        margin-top: 44px;
        margin-bottom: 0;

        li {
          font-family: $secondaryFont;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 27px;
          color: #ffffff;
          list-style: none;
          margin-bottom: 18px;

          &:last-child {
            margin-bottom: 0;
          }

          &::before {
            content: '';
            background: #5bcba5;
            display: inline-block;
            width: 9px;
            height: 9px;
            min-width: 9px;
            min-height: 9px;
            max-width: 9px;
            max-height: 9px;
            border-radius: 50%;
            margin-right: 13.81px;
          }

          span {
            font-family: $secondaryFont;
            font-style: normal;
            font-size: 20px;
            line-height: 27px;
            color: #ffffff;
            font-weight: 900;
          }
        }

        @media (max-width: 800px) {
          li {
            font-size: 16px;

            span {
              font-size: 16px;
            }
          }
        }
      }

      @media (max-width: 800px) {
        ul {
          margin-top: 8px;
        }
      }
    }

    .target-markets__right {
      width: 46%;
      margin-top: -14px;
      text-align: center;

      span {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 173px;
        line-height: 1;
        text-align: center;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #b7bdff;
      }

      p {
        font-family: $secondaryFont;
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 64px;
        text-align: center;
        color: #ffffff;
        margin-top: 5px;
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;

      .target-markets__left {
        width: 100%;
        margin: 0;
      }

      .target-markets__right {
        width: 100%;
        margin: 0;
        margin-top: 32px;

        span {
          font-size: 100px;
        }

        p {
          font-size: 20px;
          line-height: 37px;
        }
      }
    }
  }

  & > img {
    position: absolute;
    z-index: 1;

    &#left {
      top: 58px;
      left: 0;
    }

    &#right {
      top: 0;
      right: 0;
    }
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 29px;
      line-height: 37px;
    }
  }
}

#poweredby.poweredby-container {
  background: #fff;
  width: 100%;
  padding-top: 66px;
  padding-bottom: 43.52px;

  button.green-button {
    margin: 0 auto;
    margin-top: 64px;
  }

  h1 {
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 37px;
    color: #373f41;

    span {
      color: #5361f5;
    }
  }

  .poweredby__imgwrap {
    margin-top: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 44px;

    .poweredby__img {
      width: 23%;

      img {
        height: 100%;
        max-width: 100%;
      }
    }
  }

  @media (max-width: 800px) {
    padding-top: 40px;

    button.green-button {
      margin-top: 44px;
    }

    h1 {
      font-size: 29px;
      line-height: 37px;
      text-align: center;
    }

    .poweredby__imgwrap {
      margin-top: 24px;
      flex-direction: column;

      .poweredby__img {
        width: 60%;
        text-align: center;
      }
    }
  }
}

#withus.withus-container {
  width: 100%;
  background: #5361f5;
  padding-top: 56px;
  padding-bottom: 29px;
  position: relative;

  h1 {
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 53px;
    letter-spacing: 0.1px;
    color: #ffffff;
  }

  h2 {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 900;
    font-size: 96px;
    line-height: 120px;
    text-align: center;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #ffffff;
    z-index: 2;
    position: relative;
  }

  .section-inner {
    img.number-img {
      position: absolute;
      bottom: 0;
      z-index: 1;
    }

    @media (max-width: 800px) {
      img.number-img {
        display: none;
      }
    }
  }

  .withus__imgwrap {
    margin-top: 44px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 44px;

    .withus__img {
      width: 30%;
      text-align: center;

      img {
        height: 100%;
        max-width: 100%;
      }
    }
  }

  @media (max-width: 800px) {
    padding-top: 41px;
    padding-bottom: 8px;

    h1 {
      font-size: 29px;
      line-height: 37px;
      text-align: center;
    }

    h2 {
      font-size: 40px;
    }

    .withus__imgwrap {
      margin-top: 44px;
      flex-direction: column;

      .withus__img {
        width: 50%;
        text-align: center;
      }
    }
  }
}

#brands {
  width: 100%;
  background-color: #fff;
  padding-top: 82px;
  padding-bottom: 33.2px;

  h1 {
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 37px;
    color: #373f41;
    span {
      color: #5361f5;
    }

    @media (max-width: 800px) {
      text-align: center;
    }
  }
  .brands__list {
    margin-top: 108.5px;

    .brand {
      width: 23%;
      border-radius: 15px;

      img {
        border-radius: 15px;
      }

      &:nth-child(4n) {
        margin-right: 0;
      }

      &:nth-child(5n) {
        margin-right: 2%;
      }
    }

    @media (max-width: 800px) {
      display: none;
    }
  }

  .brands__list__mobile {
    margin-top: 108.5px;
    display: none;
    align-items: center;
    overflow-x: auto;

    .brands__pair {
      margin-right: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .brand {
        width: 236px;
        height: 237px;
        margin-bottom: 20px;
        overflow: hidden;
        transition: all 0.3s ease-in-out;
        position: relative;
        border-radius: 15px;
        &:hover {
          img {
            transform: scale(1.1);
          }
        }
        img {
          max-width: 100%;
          border-radius: 15px;
          transition: all 0.3s ease-in-out;
          position: relative;
          z-index: 1;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
    @media (max-width: 800px) {
      display: flex;
    }
  }

  button.green-button {
    margin: 47.52px auto 0;
  }

  p {
    max-width: 1100px;
    margin: 32.32px auto 0;
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #9b9b9b;
    padding: 0 20px;

    @media (max-width: 800px) {
      padding: 0 30px;
    }
  }
}

button.green-button {
  font-family: 'Mulish';
  font-style: normal;
  font-weight: 800;
  font-size: 23.6186px;
  line-height: 28px;
  color: #ffffff;
  width: 240.95px;
  height: 68.96px;
  background: #5bcba5;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

a.get-started {
  text-decoration: none;
  display: contents;
}
