@charset 'UTF-8';
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* @mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin user-select($select) {
  -webkit-touch-callout: $select;
  -webkit-user-select: $select;
  -khtml-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select;
  user-select: $select;
}

@mixin box() {
  background: #fff;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  padding: 1rem 1.5rem 0.5rem;
  margin: 1rem;
  text-align: center;

  h6 {
    text-align: left;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid $subtitle-underline-colour;
  }

  strong {
    margin: 1rem 0;
    display: block;
    text-align: center;
    font-size: 2.5rem;
    font-weight: 100;
    color: $number-colour;

    span {
      color: #828e99;
      font-size: 1.7rem;
      margin-right: 1rem;
    }
  }
}

@mixin button($bgColour, $fgColour, $borderColour) {
  background: $bgColour;
  color: $fgColour;
  border-radius: 0.5rem;
  box-shadow: inset 0 -2px 0 #ccc, inset 0 1px 0 #cfcfcf, inset 1px 0 0 #d4d4d4,
    inset -1px 0 0 #d4d4d4;

  &:hover {
    background: darken($bgColour, 5%);
    color: $fgColour;
  }
}

@mixin avatar($size) {
  border: 2px solid #fff;
  border-radius: $size;
  width: $size;
  height: $size;
  margin: 0.7rem;
  text-align: center;
  line-height: $size;
  font-size: 1.5rem;
}

// Mixins for breakpoints and variables

// Layout
$outer-max: 1200px;
$outer-max-thin: 614px;

@mixin display($value) {
  //  flex | inline-flex
  @if $value == "flex" {
    // 2009
    display: -webkit-box;
    display: -moz-box;
    display: box;

    // 2012
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox; // 2011 (IE 10)
    display: flex;
  } @else if $value == "inline-flex" {
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: inline-box;

    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
  } @else {
    display: $value;
  }
} */

// Breakpoints
@mixin breakpoint($point) {
  @if $point == sm {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $point == so {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $point == md {
    @media (min-width: 880px) {
      @content;
    }
  } @else if $point == lg {
    @media (min-width: 1260px) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 1600px) {
      @content;
    }
  } @else if $point == portrait {
    @media (max-width: 800px) and (orientation: portrait) {
      @content;
    }
  }
}

// Fonts
$mainFont: "Roboto", helvetica, arial, sans-serif;
$secondaryFont: "Montserrat", sans-serif;

// Colours
$mainBlue: #18bcf2;
$buttonGreen: #60ca64;
$yellow: #f8af26;
$orange: #fb7233;
$royalBlue: #4383cc;
$darkBlue: #3d5166;
$defaultText: #475059;
$lightText: #fafafa;
$primaryBgColor: #373F41;

// Misc
$greyBorder: inset 0 -1px 0 rgba(250, 250, 250, 0.12);

/* // Temporary fix until Bourbon v5
@mixin hide-text($height: null) {
  overflow: hidden;
  text-indent: -999em;
  white-space: nowrap;

  @if $height {
    @warn "The `hide-text` mixin has changed and no longer requires a height. The height argument will no longer be accepted in v5.0.0";
  }
}


@mixin blueButton {
  display: inline-block !important;
  width: 45%;
  vertical-align: middle;
  border-radius: 6px;
  background: $mainBlue;
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.2), 0 1px 4px rgba(0, 0, 0, 0.25);
  color: $lightText;
  padding: 18px 0;
  text-align: center;
  font-size: 1.55em;
  text-decoration: none;
  @include transition(background 250ms);

  &:hover {
    background: darken($mainBlue, 4%);
  }
}

@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  transition: $transition;
} */

html {
  -webkit-font-smoothing: subpixel-antialiased;
}

$orange_bg: #ee723a;
$blue: #4152fd;
$poppins: "Poppins", sans-serif;

body {
  font-family: $mainFont;
  font-weight: 300;
  color: $defaultText;
  background-color: #f5f6f7;
  .loginSignup {
    #login {
      visibility: hidden;
    }
  }

  .inner {
    h1 {
      font-size: 2.6em;
      text-align: center;
      padding: 4%;

      @include breakpoint(sm) {
        font-size: 4em;
      }
    }

    .content {
      .innerWrap {
        padding: 0 5%;

        @include breakpoint(sm) {
          padding: 0 12%;
        }

        p {
          opacity: 0.8;
          margin: 0 0 3em;
        }
      }
    }
  }
}

header#static {
  background: #5361f5;
  /* box-shadow: 0 1px 3px rgba(0,0,0,.25); */
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  padding: 10px 5%;
  width: 100vw;
  @include breakpoint(lg) {
    padding: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;

    a {
      text-decoration: none;
      color: $defaultText;
      font-size: 0.9em;
      text-align: center;
      transition: all 0.3s ease-in-out;

      @include breakpoint(lg) {
        font-size: 1.05em;
      }

      @include breakpoint(lg) {
        font-size: 1.25em;
      }

      &:hover {
        color: lighten($defaultText, 25%);
      }
      @media (max-width: 500px) {
        font-size: 13px;
      }
      &.buttonStyle {
        background: $orange_bg;
        border-radius: 2px;
        color: #ffffff;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        padding: 10px 20px;
        margin-left: 40px;
        transition: 0.25s ease-in-out;

        &:hover {
          background: darken($buttonGreen, 10%);
        }
        @media (max-width: 500px) {
          margin-left: 30px;
          font-size: 13px;
        }
      }
    }
  }

  .header__menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    div {
      &:first-child {
        padding-right: 20px;
      }
    }
  }
  nav {
    /*  @include position(absolute, -1000px 0 null 0); */
    background: rgba(252, 252, 252, 0.9);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    padding: 20px 0;
    @media (min-width: 920px) {
      display: inline-flex;
      align-items: center;
      position: static;
      box-shadow: none;
      background: none;
    }

    > span {
      /*  @include position(absolute, null null -53px calc(50% - 20px));
      @include size(40px); */
      background: url("/assets/burger.svg") no-repeat center;
      background-size: 25px;
      /*  @include hide-text;
      @include transition(background-color 275ms); */

      @include breakpoint(so) {
        left: calc(50% - 36px);
      }

      @include breakpoint(md) {
        display: none;
      }

      &.down {
        background-color: rgba(252, 252, 252, 0.9);
      }
    }

    ul {
      width: 100%;
      list-style: none;
      padding: 0;
      margin: 0;

      @include breakpoint(md) {
        width: auto;
      }

      li {
        padding-left: 5%;

        @include breakpoint(md) {
          display: inline;
          padding: 0;
        }

        a {
          display: block;
          padding: 0;
          margin: 5px 0;
          border-top: 4px solid transparent;
          border-bottom: 2px solid transparent;
          text-align: center;
          font-size: 15px;
          color: #9fa7ff;
          font-weight: 400;
          @include breakpoint(md) {
            display: inline-block;
            padding: 0;
            margin: 0 20px;
          }
          &:hover {
            color: #ffffff;
          }
          &.active {
            color: #ffffff;
            border-bottom-color: #ffffff;
          }
        }
      }
    }
  }

  #login {
    border-left: 1px solid #9fa7ff;
    padding-left: 42px;
    a {
      &:first-child {
        font-size: 15px;
        color: #ffffff;
        font-weight: 400;
        &:hover {
          color: #ffffff;
        }
      }
    }
    @media (max-width: 500px) {
      border-left: 0;
      padding-left: 0;
    }
  }
}

header#static.profiles-page {
  padding: 20px 0;
  .header__menu {
    nav {
      display: none;
    }
  }
  #login {
    border-left: 0;
  }
}

.footer{
  background-color: $primaryBgColor;
  font-family: $secondaryFont;
  width: 100%;
  color: #fff;
  padding: 50px 0 30px;
  &__top_row{
    border-bottom: 1px solid rgb(148, 148, 148);
    display: flex;
    justify-content: center;
    padding: 20px 0;  
    max-width: 1000px;
    margin: 0 auto;
    color: $lightText;

    @media screen and (max-width: 700px){
      display: grid;
      grid-template-columns: 100%;
      justify-content: flex-end;
      border-bottom: none;
    }
  }
  &__link{
    padding: 0;
    margin: 0;
    line-height: 0;
    padding: 0 20px 10px;

    @media screen and (max-width: 768px){
      padding-top: 30px;
    }

    a{
      color: $lightText;
      font-size: 14px;
      font-weight: 500;
      font-weight: 600;
    }
  }
  &__second_row{
    display: flex;
    max-width: 1000px;
    margin: 0 auto;
    justify-content: space-around;
    padding: 20px 0 20px;
    grid-gap: 40px;

    @media screen and (max-width: 768px){
      display: grid;
      grid-template-columns: 100%;
      padding-left: 20px;
    }
  }
  &__third_row{
    text-align: center;
    padding: 0 0 20px;
    @media screen and (max-width:700px){
      font-size: 14px;
      padding: 15px 5px;
    }
    span{
      display: inline;
      font-size: 13px;
    }
    a{
      display: block;
      font-size: 13px;
      color: #fff;
      padding-top: 5px;
    }
  }
}


h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
  margin: 0;
}

h2 {
  font-size: 2em;

  @include breakpoint(sm) {
    font-size: 2.3em;
  }

  @include breakpoint(lg) {
    font-size: 3em;
  }
}

p {
  font-size: 0.95em;

  @include breakpoint(sm) {
    font-size: 1.05em;
  }

  @include breakpoint(lg) {
    font-size: 1.25em;
  }
}

.innerWrap {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  @media (max-width: 1250px) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

ul.mainList {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    font-size: 22px;
    color: #ffffff;
    line-height: 26px;
    margin: 2.5px 0;
    margin-bottom: 10px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon {
      width: 35px;
      font-size: 24px;
      margin-right: 8px;
    }
    svg {
      fill: #ffffff;
    }
    @include breakpoint(sm) {
      font-size: 1.2em;
    }

    /* &:before {
      content: "";
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      background: #ffffff;
      border-radius: 50%;
    } */
  }
}

// Homepage
#features {
  background: #4152fd
    /*  url("/assets/images/banner-bg1.png") no-repeat center center */;
  /*  background-size: contain; */
  padding-top: 150px;
  padding-bottom: 220px;
  /* padding-bottom: 320px; */
  overflow-x: hidden;
  h2 {
    font-family: $poppins;
    font-weight: bold;
    font-size: 48px;
    line-height: 54px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  @media (max-width: 800px) {
    background-size: cover;
    padding-bottom: 160px;
    .bx-wrapper {
      display: flex;
      justify-content: center;
      img {
        margin: 0 auto;
      }
    }
  }
  p {
    font-size: 22px;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 15px;
  }
  .features__container {
    position: relative;
  }
  .features__monitor {
    position: absolute;
    /* right: -1.2%;
    top: -27px;
    width: 52.2%; */
    right: 5.8%;
    top: -26px;
    width: 45.2%;
  }
  .slick-active {
    .slider__descr {
      left: 17.7%;
      opacity: 1;
    }
  }
  .features__slider .item {
    /* display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start; */
    position: relative;
    /* height: 40vh; */
  }
  .slider__descr {
    width: 460px;
    position: absolute;
    left: 52%;
    top: 50%;
    transform: translateY(-50%);
    transition: all 2s ease;
    opacity: 0;
    z-index: 0;
  }
  .active-slide {
    .slider__descr {
      left: 17.7%;
      opacity: 1;
    }
  }
  .slider__descr.anim-descr {
    left: -100%;
    opacity: 0.5;
  }
  .slider__image {
    width: 49.9%;
    position: relative;
    z-index: 1;
    margin-left: auto;
    margin-right: 0;
    img {
      /* max-width: 73%; */
      max-width: 63%;
    }
  }
  @media (max-width: 1600px) {
    /* padding-bottom: 250px; */
    padding-bottom: 190px;
    .active-slide {
      .slider__descr {
        left: 15%;
      }
    }
    .features__monitor {
      top: -21px;
    }
  }
  @media (max-width: 1350px) {
    .slider__descr {
      width: 34%;
    }
    .slick-active .slider__descr {
      left: 13%;
    }
  }
  @media (max-width: 1200px) {
    .features__monitor {
      top: -19px;
      right: -8.3%;
      width: 60%;
    }
    .active-slide {
      .slider__descr {
        left: 40px;
        width: 45%;
      }
    }
    .slider__image {
      img {
        max-width: 84%;
      }
    }
  }
  @media (max-width: 1100px) {
    h2 {
      font-size: 45px;
    }
    p {
      font-size: 20px;
      line-height: 24px;
    }
    ul.mainList {
      li {
        font-size: 1.1em;
      }
    }
  }
  @media (max-width: 900px) {
    /* padding-bottom: 200px; */
    overflow-x: visible;
    background-size: cover;
    overflow-y: hidden;
    .item {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 40px;
    }
    .slider__descr {
      position: static;
      transform: none;
      width: 80%;
      margin-bottom: 60px;
      min-height: 280px;
    }
    .active-slide {
      .slider__descr {
        width: 80%;
        position: static;
        transform: none;
        min-height: 280px;
      }
    }
    .slider__image {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 430px;
        transform: translate(-3px, 8px);
      }
    }
    .features__slider > div {
      flex-direction: column;
      align-items: flex-start;
    }
    .features__monitor {
      top: auto;
      bottom: -19.2%;
      right: 50%;
      width: 622px;
      transform: translateX(62.5%);
    }
  }
  @media (max-width: 600px) {
    padding-top: 60px;
    .features__monitor {
      width: 421px;
      bottom: -17%;
    }
    .slider__image {
      img {
        width: 293px;
        max-width: 100%;
      }
    }
    .slider__descr {
      width: 100%;
      min-height: 220px;
    }
    .active-slide {
      .slider__descr {
        width: 100%;
        min-height: 220px;
      }
    }
    h2 {
      font-size: 36px;
      line-height: 38px;
    }
    p {
      font-size: 16px;
      line-height: 19px;
    }
    ul.mainList {
      li {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
  @media (max-width: 500px) {
    .features__monitor {
      bottom: -17%;
    }
  }
  @media (max-width: 450px) {
    .features__monitor {
      bottom: -16.7%;
      transform: translateX(62%);
      width: 417px;
      height: 259px;
    }
    .slider__image {
      img {
        width: 288px;
        height: 172px;
      }
    }
  }
  @media (max-width: 400px) {
    .slider__image {
      img {
        width: 89%;
        height: 172px;
      }
    }
  }
  @media (max-width: 370px) {
    .features__monitor {
      bottom: -15.5%;
    }
    .active-slide {
      .slider__descr {
        min-height: 250px;
      }
    }
    .slider__descr {
      min-height: 250px;
    }
  }
  @media (max-width: 360px) {
    .slider__image {
      img {
        width: 90%;
      }
    }
  }
  @media (max-width: 345px) {
    .active-slide {
      .slider__descr {
        min-height: 270px;
      }
    }
    .slider__descr {
      min-height: 270px;
    }
    .features__monitor {
      bottom: -15%;
    }
    .slider__image {
      img {
        width: 92%;
      }
    }
  }
}

#terms-top {
  background: #4152fd;
  width: 100%;
  padding: 100px 0;
  overflow-x: hidden;
  h2 {
    font-family: $poppins;
    font-weight: bold;
    font-size: 48px;
    line-height: 54px;
    color: #ffffff;
    margin-bottom: 20px;
    text-align: center;
  }
  @media (max-width: 800px) {
    background-size: cover;
    padding-bottom: 160px;
    .bx-wrapper {
      display: flex;
      justify-content: center;
      img {
        margin: 0 auto;
      }
    }
  }
  p {
    font-size: 22px;
    line-height: 26px;
    color: #ffffff;
    margin-bottom: 15px;
  }

  @media (max-width: 1600px) {
    /* padding-bottom: 250px; */
    //padding-bottom: 190px;
  }
  @media (max-width: 900px) {
    /* padding-bottom: 200px; */
    overflow-x: visible;
    background-size: cover;
    overflow-y: hidden;
  }
  @media (max-width: 600px) {
    h2 {
      font-size: 36px;
      line-height: 38px;
    }
    p {
      font-size: 16px;
      line-height: 19px;
    }
    ul.mainList {
      li {
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}

#steps {
  background: #ffffff;
  padding: 80px 80px 20px 80px;
  .step__list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .step__item {
    width: 30%;
    background-color: #f5f6ff;
    border-radius: 5px;
    padding: 15px 40px;
    padding-bottom: 35px;
    position: relative;
    transition: all 0.3s ease;
    &:hover {
      box-shadow: 0px 2px 10px rgba(193, 197, 226, 0.65);
    }
    .num {
      font-family: $poppins;
      font-size: 96px;
      font-weight: 900;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: $blue;
      color: #ffffff;
      position: absolute;
      display: block;
      left: -20px;
      top: -40px;
      line-height: 75px;
    }
    h3 {
      font-family: $poppins;
      color: $blue;
      font-size: 36px;
      line-height: 45px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    p {
      font-size: 16px;
      color: #475059;
      line-height: 19px;
      margin-bottom: 0;
    }
  }
  @media (max-width: 800px) {
    .step__list {
      flex-direction: column;
      align-items: center;
    }
    .step__item {
      width: 100%;
      margin-bottom: 15px;
      .num {
        display: none;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 60px 0;
    .step__item {
      padding: 30px;
      padding-top: 15px;
    }
  }
  @media (max-width: 500px) {
    .step__item {
      h3 {
        font-size: 24px;
        line-height: 36px;
        margin-bottom: 10px;
      }
      p {
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
}

.block__title {
  font-weight: bold;
  font-size: 36px;
  line-height: 54px;
  font-family: $poppins;
  text-align: center;
  margin-bottom: 40px;
  color: $blue;
  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 36px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}

#benefits {
  .innerWrap {
    padding: 0;
  }
  .benefits__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .benefits__description {
    width: 47%;
    p {
      font-size: 16px;
      line-height: 19px;
      color: $defaultText;
      margin-bottom: 20px;
    }
    ul {
      li {
        font-size: 16px;
        line-height: 19px;
        color: $defaultText;
        padding-left: 24px;
        &:before {
          left: 10px;
          background-color: $defaultText;
        }
      }
    }
  }
  .benefits__table {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 5px;
    width: 50%;
  }
  .data-title {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    h4 {
      font-weight: bold;
      font-size: 18px;
      line-height: 21px;
      color: $defaultText;
    }
  }
  .dataRow {
    width: 100%;
    background: #f5f6ff;
    border-radius: 5px;
    overflow: hidden;
    padding: 5px 8px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
      &:after {
        width: 10%;
      }
    }
    &:nth-child(2) {
      &:after {
        width: 50%;
      }
    }
    &:nth-child(3) {
      &:after {
        width: 40%;
      }
    }
    &:nth-child(4) {
      &:after {
        width: 35%;
      }
    }
    &:nth-child(5) {
      &:after {
        width: 25%;
      }
    }
    &:after {
      content: "";
      position: absolute;
      height: 105%;
      left: 0;
      top: 0;
      background-color: $blue;
      z-index: 0;
    }
    span {
      position: relative;
      z-index: 1;
      font-weight: 500;
      color: $defaultText;
      font-size: 13px;
      line-height: 15px;
      &:first-child {
        font-weight: 500;
        color: #ffffff;
        font-size: 13px;
        line-height: 15px;
      }
    }
  }
  @media (max-width: 1250px) {
    .innerWrap {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
  @media (max-width: 900px) {
    .benefits__row {
      flex-direction: column-reverse;
    }
    .benefits__description {
      width: 70%;
    }
    .benefits__table {
      width: 70%;
      margin-bottom: 30px;
    }
  }
  @media (max-width: 700px) {
    .benefits__description {
      width: 100%;
    }
    .benefits__table {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    padding: 60px 0;
  }
  @media (max-width: 500px) {
    .benefits__description {
      p {
        font-size: 13px;
        line-height: 15px;
        margin-bottom: 15px;
      }
      ul {
        li {
          font-size: 13px;
          line-height: 15px;
        }
      }
    }
  }
}

.home-content {
  width: 100%;
}

.content {
  background: #f5f6ff;
  padding: 100px 0;
  width: 100%;
}

#brands {
  background: #ffffff;
  padding: 100px 0;
  padding-bottom: 70px;
  .brands__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .brand {
    width: 18%;
    margin-right: 2%;
    margin-bottom: 30px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    position: relative;
    border-radius: 5px;
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    &:nth-child(5n) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    img {
      max-width: 100%;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;
      position: relative;
      z-index: 1;
    }
  }
  .brand.coming-soon {
    position: relative;
    &:after {
      content: "Coming soon";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0.6);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      text-transform: uppercase;
    }
    &:hover {
      &:after {
        opacity: 1;
        visibility: visible;
        z-index: 2;
      }
    }
  }
  @media (max-width: 800px) {
    .brand {
      width: 31%;
      &:nth-child(5n) {
        margin-right: 2%;
      }
      &:nth-child(3n) {
        margin-right: 0%;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 60px 0;
    padding-bottom: 30px;
  }
  @media (max-width: 500px) {
    .brand {
      width: 48%;
      margin-bottom: 10px;
      &:nth-child(3n) {
        margin-right: 2%;
      }
      &:nth-child(2n) {
        margin-right: 0%;
      }
    }
  }
}

#about {
  background: #f5f6ff;
  padding: 100px 0;
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    color: #475059;
    margin: 0 auto;
    margin-bottom: 20px;
    text-align: center;
    max-width: 710px;
    &:last-child {
      margin-bottom: 0;
    }
    strong {
      text-transform: uppercase;
    }
  }
  @media (max-width: 600px) {
    padding: 60px 0;
  }
  @media (max-width: 500px) {
    p {
      margin-bottom: 15px;
      font-size: 13px;
      line-height: 15px;
    }
  }
}

.our-team {
  padding: 100px 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  a {
    max-width: 100%;
    width: 560px;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: $blue;
    transition: all 0.3s ease;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    text-decoration: none;
    margin: 0 auto;
    &:hover {
      background-color: darken($blue, 10%);
    }
  }
  @media (max-width: 600px) {
    padding: 60px 0;
  }
  @media (max-width: 500px) {
    a {
      font-size: 13px;
    }
  }
}

.home-contact {
  padding: 100px 0;
  // background-color: $blue;
  background-image: url('../../assets/Get\ in\ touch.svg');
  background-size: cover;
  background-repeat: none;
  background-position: bottom;

  .contact{
    background-color: $blue;
    &__title{
      color: #fff;
      line-height: 0.1;
    }

    &__time{
      text-align: center;
      display: block;
      letter-spacing: 0.1;
      font-weight: bold;
      font-size: 22px;
      color: #fff;
    }
    &__text{
      text-align: center;
      letter-spacing: 0.1;
      font-size: 16px;
      color: #fff;
      max-width: 900px;
      margin: 0 auto;
      padding: 6px 0;
    }

    &__form_recaptcha{
      display: flex;
      justify-content: center;
      padding-top: 20px;

      @media screen and (max-width:700px){
        width: 80%;
        margin: 0 auto;
        // background-color: green;
      }

      .rc-anchor{
        width: 40%;
      }
    }
    &__form_btn{
      text-align: center;
      padding-top: 20px;
      input{
        padding: 17px 54px;
        border-radius: 4px;
        border: none;
        color: #fff;
        background-color: #5BCBA5;
        font-size: 18px;
      }
    }
  }

  .form{
    &__field{
      input{
        width: 700px;
        margin: 0 auto 20px;
        padding: 27px 20px;
        border-radius: 10px;
        border: none;
      }

      input::placeholder{
        color: rgb(204, 204, 204);
        font-weight: 300;
        font-size: 15px;
        letter-spacing: 2px;
      }

      textarea{
        width: 700px;
        margin: 0 auto;
        border-radius: 10px;
        padding: 20px;
        height: 200px;
      }

      textarea::placeholder{
        color: rgb(204, 204, 204);
      }
    }
  }
  @media (max-width: 600px) {
    padding: 60px 0;
  }
  @media (max-width: 500px) {
    .form__field {
      label {
        font-size: 13px;
        line-height: 15px;
      }
    }
    .form__btn {
      margin-top: 10px;
      input {
        font-size: 13px;
      }
    }
    .contact__text {
      width: 100%;
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 30px;
    }
    .contact__time {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 15px;
    }
  }
}

.standardForm {
  padding: 0 0 20px;

  @include breakpoint(sm) {
    padding: 0 8% 40px;
    clear: both;
  }

  fieldset {
    padding: 0;
    margin: 0 0 20px;
    border: 0;

    > label {
      display: block;
      margin: 0 0 10px;
      font-size: 1em;

      @include breakpoint(sm) {
        /* 
        @include span-columns(5); */
        margin: 10px 0 0;
        padding-left: 30px;
        font-size: 1.15em;
      }
    }

    > input,
    > textarea {
      width: 100%;
      background: $lightText;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      font-size: 1em;
      padding: 10px;

      @include breakpoint(sm) {
        /* @include span-columns(7); */
        float: right;
        font-size: 1.15em;
      }
    }

    textarea {
      height: 46px;
    }
  }

  label {
    /* @include user-select(none); */
  }

  button {
    width: 100%;
    /*  @include blueButton; */
    border: 0;
    font-size: 1.2em;
    padding: 12px 0;

    @include breakpoint(sm) {
      width: auto;
      /* @include span-columns(7); */
      float: right;
      margin: 30px 0 0;
    }
  }

  div {
    margin: 20px 0 0;
    text-align: center;
    font-size: 1.1em;

    @include breakpoint(sm) {
      /* @include span-columns(7); */
      float: right;
    }

    &.contactOpts {
      margin: 10px 0 0;
      text-align: left;

      label {
        /*  @include span-columns(4); */
      }

      input {
        margin-right: 5px;
      }
    }

    &.termsBox {
      text-align: left;
      margin-bottom: 20px;
      margin-right: 0;

      label,
      a {
        color: #ffffff;
      }

      input {
        margin-right: 5px;
      }

      a {
        display: inline;
      }
    }

    a,
    span {
      display: block;
      opacity: 0.9;
      color: $defaultText;
    }

    a {
      text-decoration: underline;

      &:first-child {
        margin: 0 0 40px;
      }

      &:hover {
        color: $mainBlue;
      }
    }

    span {
      &:first-child {
        margin: 20px 0 0;
      }
    }
  }
}

// Login

// Signup
#regList {
  width: 95%;
  margin: 0 auto 30px;
  overflow: hidden;
  list-style: inside;

  li {
    float: left;
    width: 50%;
    font-size: 1.3em;
  }

  & + p {
    text-align: center;
  }
}

// Terms
.terms {
  ol {
    counter-reset: item;
    padding-left: 2.3em;

    @include breakpoint(sm) {
      padding-left: 1em;
    }

    li {
      display: block;
      position: relative;
      color: $royalBlue;
      font-size: 1.9em;

      &:before {
        content: "."; /* counters(item, "."); */
        /* counter-increment: item; */
        position: absolute;
        margin-right: 100%;
        right: 10px; /* space between number and text */
      }

      @include breakpoint(sm) {
        font-size: 3.4em;
        margin-bottom: 1.3em;

        &:before {
          right: 30px;
        }
      }

      ol {
        margin: 0.4em 0 0.5em;
        border-top: 2px solid #d8d8d8;
        padding-left: 0;

        li {
          color: $defaultText;
          font-size: 1.4rem;
          line-height: 1.4em;
          margin: 0.6em 0;

          p {
            font-size: 1rem;
            line-height: 1.7em;
            margin: 0.6em 0;
          }

          @include breakpoint(sm) {
            font-size: 1.7rem;
            margin: 1.3em 0 1em;

            p {
              margin: 1em 0 0 !important;
              font-size: 1.1rem;
              line-height: 1.4;
            }
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.terms-wrap {
  p {
    white-space: pre-line;
    font-size: 16px;
    line-height: 19px;
    color: #475059;
  }
}

// BX Slider styles
/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;
  *zoom: 1;
}

.bx-wrapper img {
  max-width: 100%;
  display: block;
}

/** THEME
===================================*/

.bx-wrapper .bx-viewport {
  left: -5px;

  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
}

.slick-dots,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  /* bottom: -60%; */
  bottom: -52%;
  width: 100%;
  left: 0;
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 900px) {
    bottom: -28%;
  }
  @media (max-width: 600px) {
    bottom: -32%;
  }
}

.slick-dots li {
  background: transparent;
  text-indent: -9999px;
  display: flex;
  width: 10px;
  height: 10px;
  margin: 0 15px;
  border: 2px solid #ffffff;
  outline: 0;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.slick-dots .slick-active {
  background-color: #ffffff;
}

.slick-dots li button:before {
  color: transparent;
}

.slick-dots li.slick-active button:before {
  color: transparent;
}

/* LOADER */

.bx-wrapper .bx-loading {
  min-height: 50px;
  // background: url(/assets/bx_loader.gif) center center no-repeat transparent;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

/* PAGER */

.bx-wrapper .bx-pager {
  text-align: center;
  font-size: 0.85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
}

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: transparent;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 15px;
  border: 2px solid #ffffff;
  outline: 0;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #ffffff;
}

/* DIRECTION CONTROLS (NEXT / PREV) */

.slick-arrow.slick-prev {
  left: 45.5%;
  background: url(/assets/images/sl-prev-new.png) no-repeat center;
  @media (max-width: 1600px) {
    left: 44%;
    bottom: -53.3%;
  }
  @media (max-width: 1100px) {
    left: 42%;
  }
}

.slick-arrow.slick-next {
  right: 45.5%;
  background: url(/assets/images/sl-next-new.png) no-repeat center;
  @media (max-width: 1600px) {
    right: 44%;
    bottom: -53.3%;
  }
  @media (max-width: 1100px) {
    right: 42%;
  }
}

.slick-arrow {
  position: absolute;
  /* bottom: -57%; */
  bottom: -52.8%;
  top: auto;
  margin-top: 0;
  outline: 0;
  width: 7px;
  height: 10px;
  z-index: 9998;
  display: block;
  &:before {
    display: none;
  }
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 1600px) {
    .bx-controls-direction {
      a {
        /* bottom: -56.4%; */
        bottom: -51%;
      }
    }
    .bx-next {
      right: 45%;
    }
    .bx-prev {
      left: 45%;
    }
  }
  @media (max-width: 1400px) {
    .bx-next {
      right: 44%;
    }
    .bx-prev {
      left: 44%;
    }
  }
  @media (max-width: 1050px) {
    .bx-next {
      right: 42%;
    }
    .bx-prev {
      left: 42%;
    }
  }
  @media (max-width: 900px) {
    .bx-controls-direction {
      a {
        bottom: -26%;
      }
    }
    .bx-next {
      right: 41%;
    }
    .bx-prev {
      left: 41%;
    }
  }
  @media (max-width: 700px) {
    .bx-next {
      right: 38%;
    }
    .bx-prev {
      left: 38%;
    }
  }
  @media (max-width: 600px) {
    .bx-controls-direction {
      a {
        bottom: -29.5%;
      }
    }
    .bx-next {
      right: 37%;
    }
    .bx-prev {
      left: 37%;
    }
  }
  @media (max-width: 500px) {
    .bx-next {
      right: 33%;
    }
    .bx-prev {
      left: 33%;
    }
  }
  @media (max-width: 450px) {
    .bx-next {
      right: 31%;
    }
    .bx-prev {
      left: 31%;
    }
  }
}

.bx-wrapper .bx-controls-direction a.disabled {
  display: none;
}

/* AUTO CONTROLS (START / STOP) */

.bx-wrapper .bx-controls-auto {
  text-align: center;
}

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  /* background: url(images/controls.png) -86px -11px no-repeat; */
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
  background-position: -86px 0;
}

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  /* background: url(images/controls.png) -86px -44px no-repeat; */
  margin: 0 3px;
}

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
  background-position: -86px -33px;
}

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%;
}

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px;
}

/* IMAGE CAPTIONS */

.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
}

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: 0.85em;
  padding: 10px;
}

.tr-total {
  font-weight: 900;
  font-family: sans-serif;
}

/*************Profile page start*************/

.profiles {
  padding-top: 180px;
  padding-bottom: 100px;
  width: 100%;
  .block__title {
    font-size: 36px;
    line-height: 54px;
    padding: 0;
  }
  .profiles__list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .profile {
    width: 23%;
    background-color: #ffffff;
    box-shadow: 0px 4px 10px rgba(193, 197, 226, 0.3);
    border-radius: 5px;
    margin-right: 2.6%;
    margin-bottom: 30px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      border-radius: 50%;
      margin-bottom: 20px;
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .profile__title {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #475059;
    display: block;
    text-align: center;
    margin-bottom: 10px;
  }
  .profile__position {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #475059;
    display: block;
    margin-bottom: 10px;
  }
  .profile__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #475059;
    display: block;
  }
  @media (max-width: 1000px) {
    .profile {
      width: 31%;
      margin-right: 3%;
      &:nth-child(4n) {
        margin-right: 3%;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 800px) {
    .profile {
      width: 47%;
      margin-right: 4%;
      &:nth-child(3n) {
        margin-right: 4%;
      }
      &:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
  @media (max-width: 600px) {
    .profile {
      width: 100%;
      margin-right: 0 !important;
      flex-direction: row;
      justify-content: space-between;
      img {
        margin-bottom: 0;
      }
    }
    .profile__descr {
      width: 50%;
    }
    .profile__title {
      text-align: left;
    }
    .profile__position {
      text-align: left;
    }
    .profile__text {
      text-align: left;
    }
  }
  @media (max-width: 500px) {
    padding-top: 140px;
    padding-bottom: 45px;
    .profile {
      margin-bottom: 15px;
      img {
        width: 100px;
        height: 100px;
      }
    }
    .profile__title {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 15px;
    }
    .profile__position {
      font-size: 13px;
      line-height: 15px;
    }
    .profile__text {
      font-size: 13px;
      line-height: 15px;
    }
  }
}

/*************Profile page end*************/

@media (max-width: 768px) {
  body .inner .content.content-login {
    .innerWrap {
      padding: 0;
    }
  }
}

.content-login {
  padding-top: 180px;
  @media (max-width: 768px) {
    .innerWrap {
      padding: 0;
    }
    padding-top: 62px;
    padding-bottom: 40px;
  }
}

.form__auth-container {
  max-width: 708px;
  background: #4152fd;
  border-radius: 5px;
  padding: 100px 74px;
  margin: 0 auto;
  margin-bottom: 40px;
  h1 {
    font-family: $poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 36px !important;
    line-height: 54px;
    color: #ffffff;
    margin-bottom: 40px;
    padding: 0 !important;
  }
  p.auth-notice {
    margin-bottom: 40px !important;
    text-align: center;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    opacity: 1 !important;
  }
  .standardForm {
    padding: 0;
    width: 100%;
    div {
      margin-right: 5.65765%;
      margin-top: 12px;
    }
    button {
      background: #ee723a;
      border-radius: 5px;
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
      transition: all 0.3s ease-in-out;
      &:hover {
        background: darken(#ee723a, 10%);
      }
    }
    .create-acc {
      float: none;
      width: 100%;
      margin: 0;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      a {
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        display: block;
        color: #ffffff;
        text-decoration: none;
        margin-bottom: 20px;
        margin-top: 40px;
        opacity: 1 !important;
      }
      span {
        font-weight: normal;
        font-size: 13px;
        line-height: 15px;
        color: #ffffff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        opacity: 1 !important;
        margin-top: 0 !important;
        a {
          margin-bottom: 0;
          margin-left: 3px;
          margin-top: 0;
        }
      }
    }
    .login-acc {
      padding-top: 40px;
    }
  }
  fieldset {
    label {
      padding-left: 0;
      width: 20%;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
    input {
      width: 80%;
      background: #6471f6;
      border: 1px solid #9fa7ff;
      box-sizing: border-box;
      border-radius: 5px;
      height: 40px;
      padding-left: 7px;
      outline: none;
      transition: all 0.3s ease;
      font-size: 16px;
      color: #ffffff;
      margin-bottom: 0;
      float: none;
      &:focus {
        box-shadow: 0px 0px 10px #9fa7ff;
        background: #6471f6;
      }
    }
  }
  @media (max-width: 900px) {
    .standardForm {
      div {
        margin-right: 15%;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 40px;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    .standardForm {
      div {
        margin-right: 15%;
        margin-top: 50px;
      }
      .create-acc {
        margin-top: 0;
        a {
          margin-top: 0;
        }
      }
      button {
        margin-top: 20px;
      }
    }
    fieldset {
      input {
        width: 100%;
      }
    }
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 24px !important;
      line-height: 32px;
      margin-bottom: 30px;
    }
    p.auth-notice {
      font-size: 13px;
      line-height: 15px;
      margin-bottom: 30px !important;
    }
  }
}

.bottom__links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  a {
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #4152fd;
    text-decoration: none;
    margin: 0 20px;
  }
}

.button-signup-page{
  background-color: #5bcba5;
  padding: 15px;
  font-size:35px;
  color: white;
  text-transform: uppercase;
  border-radius: 20px;
}
.about-us-title {
  font-weight: bold;
  text-align: center;
  margin-bottom:20px;
}
