// This is the style sheet for the reveiws component

.review{
    width: 100%;
    padding: 50px 0 0px;
    background-color: #fff;
    font-family: "Montserrat", sans-serif;
    position: relative;
    

    &__background_assets{
        position: absolute;
        z-index: 8;
        width: 100%;
        opacity: 0.7;

        @media screen and (max-width:1200px){
            opacity: 0.2;
        }

        svg{
            position: absolute;
        }

        svg:nth-child(1){
            top: 270px;
            right: 0;
            position: absolute;
        }

        svg:nth-child(2){
            top: 300px;
            left: 0;
        }

        svg:nth-child(3){
            top: 900px;
            left: 5%;
            // display: none;  // remember to disable this when the page grows 
        }

        svg:nth-child(4){
            top: 1900px;
            left: 0;
            display: none; // remember to disable this when the page grows
        }
    }

    &__inner{
        max-width: 1100px;
        margin: 0 auto;
        position: relative; 
        z-index: 10;

        @media screen and (max-width:567px){
            padding: 0px 20px;
        }
    }

    &__last_inner{
        padding-bottom: 60px;
    }

    &__heading{
        text-align: center;
        font-weight: 800;
        padding-bottom: 50px;

        @media screen and (max-width: 567px){
            padding-bottom: 20px;
        }
    }
    &__info{
        width: 100%;
    }
    &__grid{
        display: grid;
        grid-template-columns: 33% 33% 33%;
        grid-gap: 20px 0px;
        justify-content: center;

        @media screen and (max-width: 880px){
            grid-template-columns: 50% 50%;
        }

        @media screen and (max-width: 578px){
            grid-template-columns: 100%;
        }
    }
    &__quote-left{
        font-size: 180px;
        text-align: center;
        padding: 0;
        opacity: 0.1;
        transform: scaleY(-1);
        -webkit-transform: scaleY(-1);

        svg{
           transform: scaleX(-1); 
           -webkit-transform: scaleX(-1);
        }

        @media screen and (max-width:880px){
            display: none;
        }
    }
    &__empty_holder{
        @media screen and (max-width:880px){
            display: none;
        }
    }

    // hear from our partners styling
    &__hear-from-our-partners{
        font-family: "Montserrat", sans-serif;
        color: #5760ED;
        padding: 50px 0;
        position: relative; 
        left: -18px;

        @media screen and (max-width:768px){
            display: none;
        }
        p:first-child{
            font-size: 59px;
            line-height: 38.5px;
            font-style: italic;
            font-size: 59px;
            line-height: 38.5px;
            span{
                font-size: 220px;
                font-weight: bold;
                font-style: normal;
            }
        }

        p:last-child{
            font-weight: 900;
            font-style: normal;
            font-size: 69px;
            line-height: 38.5px;
            text-align: center;
        }
    }
}

// Styling for the single review component
.single-review{
    padding: 10px;

    &__link{
        font-size: 18px;
        color: #5760ED;
        font-weight: 700;
        font-style: normal;
        font-family: "Montserrat", sans-serif;
        line-height: 39px;
    }

    &__paragraph{
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
        color: #302f2f;
    }
    &__author{
        font-style: italic;
        color: rgb(63, 63, 63);
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
    }
}