.sign-in-title {
  font-size: 40px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
}

.MuiFormControl-root {
  width: 100%;
}

.MuiGrid-grid-xs-12 {
  padding-bottom: 20px;
}

form {
  margin-top: 10px;
  width: 100%;
}

.my-node-enter {
  opacity: 0;
  visibility: hidden;
}
.my-node-enter-active {
  opacity: 1;
  transition: all 200ms ease-in-out;
  visibility: visible;
}
.my-node-exit {
  opacity: 1;
  visibility: visible;
}
.my-node-exit-active {
  opacity: 0;
  visibility: hidden;
  transition: all 200ms ease-in-out;
}
